import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div>
      <section className="home_sec7">
        <p>© Copyrights 2024 elegent interiors. All Rights are Reserved.</p>
      </section>
    </div>
  );
};

export default Footer;
